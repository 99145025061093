import React, { Component } from "react";
import { Container, Carousel, CarouselInner, CarouselItem, Col, Row } from "mdbreact";
import slideshow from '../videos/Slideshow.mp4';

class AccommodationPage extends Component {
  render() {
    return (
      <Container style={{ marginTop: "5rem" }}>
        <div className="container mt-4">
          <div className="row">
            <div className="col-md-12">
              <Carousel
                activeItem={1}
                length={1}
                showControls={false}
                showIndicators={false}
                className="z-depth-1"
              >
                <CarouselInner>
                  <CarouselItem itemId="1">
                    <video className="video-fluid d-block" autoPlay loop>
                      <source
                        src={slideshow}
                        type="video/mp4"
                      />
                    </video>
                  </CarouselItem>
                </CarouselInner>
              </Carousel>
            </div>
          </div>
        </div>
        <Row>
          <Col md="10" className="mx-auto mt-4">
            <h2 className="text-center my-4 font-weight-bold">
              Accommodation information
            </h2>
            <p className="text-center">
              Holiday Home Barbara is located on the beautiful island of Ciovo,
              in the village Okrug Gornji, 6 km far from the UNESCO-protected Old Town of Trogir.
            </p>
            <p className="text-center">
              The house is ideal for relaxing family vacation and it will give you the complete
              experience of peace and comfort. With our stunning sea views you can completely
              relax and recharge your batteries.
            </p>
            <p className="text-center">
              House consists of 2 bedrooms furnished with double beds, bathroom with bath-tub,
              dining room, fully equipped kitchen (kitchen utensilies, cuttlery, fridge, freezer,
              stove, oven, coffe maker, kettle, toaster) and living area consisting of convertible
              sofabed (offers 1-2 additional places to sleep) and armchair.
            </p>
            <p className="text-center">
              There is a sunshaded, south-facing positioned balcony throughout the living area,
              equipped with table and 2 deck-chairs.
            </p>
            <p className="text-center">
              During your stay entire house and its garden are at your desposal.
              House is surrounded by a typical Mediterranean garden. You can enjoy yourself in intact
              nature, natural shade of pine trees and greenery. Garden furniture consistists of table
              and 6 chairs. Barbecue is at your disposal. There is a private parking space in the yard.
            </p>
            <p className="text-center">
              Besides from Trogir, we would recommend you to visit Split, another UNESCO-protected,
              more than 1700 years old town and center of Split-Dalmatia County. Split is very well
              connected to Trogir. It will take you about 30 mins of drive on the main road. Besides
              from your car, travel by bus from the Trogir Bus Station.
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default AccommodationPage;