import React from "react";
import FontAwesome from 'react-fontawesome';

import "./HomePage.css";
import slide1 from '../images/HomePage_1.jpg';
import slide2 from '../images/HomePage_2.jpg';
import slide3 from '../images/HomePage_3.jpg';
import slide4 from '../images/HomePage_4.jpg';
import slide5 from '../images/HomePage_5.jpg';
import slide6 from '../images/HomePage_6.jpg';
import slide7 from '../images/HomePage_7.jpg';

import {
  EdgeHeader,
  Footer,
  FreeBird,
  Container,
  Carousel,
  CarouselCaption,
  CarouselInner,
  CarouselItem,
  Input,
  Button,
  View,
  Mask,
  Col,
  Row,
  CardBody,
  Fa
} from "mdbreact";
const NavLink = require("react-router-dom").NavLink;

class HomePage extends React.Component {
  render() {
    return (
      <div>
        <EdgeHeader color="blue darken-3" style={{ marginTop: "-6rem" }}/>
        <FreeBird>
          <Row>
            <Col
              md="15"
              className="mx-auto float-none white z-depth-1 py-2 px-2"
            >
              <CardBody>
                <Carousel
                  activeItem={1}
                  length={7}
                  showControls={true}
                  showIndicators={true}
                  className="z-depth-1"
                >
                  <CarouselInner>
                    <CarouselItem itemId="1">
                      <View>
                        <img
                          className="d-block w-100"
                          src={slide1}
                          alt={slide1}
                        />
                        <Mask overlay="black-slight" />
                      </View>
                      <CarouselCaption>
                        <h3 className="h3-responsive" style={{ textShadow: '2px 2px 2px rgba(0, 0, 0, 100)' }}>Balcony</h3>
                        <p style={{ textShadow: '2px 2px 2px rgba(0, 0, 0, 100)' }}>Beautiful view of the sea</p>
                      </CarouselCaption>
                    </CarouselItem>
                    <CarouselItem itemId="2">
                      <View>
                      <img
                          className="d-block w-100"
                          src={slide2}
                          alt={slide2}
                        />
                        <Mask overlay="black-slight" />
                      </View>
                      <CarouselCaption>
                        <h3 className="h3-responsive" style={{ textShadow: '2px 2px 2px rgba(0, 0, 0, 100)' }}>Kitchen</h3>
                        <p style={{ textShadow: '2px 2px 2px rgba(0, 0, 0, 100)' }}>Have a nice meal in a fully equipped kitchen</p>
                      </CarouselCaption>
                    </CarouselItem>
                    <CarouselItem itemId="3">
                      <View>
                      <img
                          className="d-block w-100"
                          src={slide3}
                          alt={slide3}
                        />
                        <Mask overlay="black-slight" />
                      </View>
                      <CarouselCaption>
                        <h3 className="h3-responsive" style={{ textShadow: '2px 2px 2px rgba(0, 0, 0, 100)' }}>Living Room</h3>
                        <p style={{ textShadow: '2px 2px 2px rgba(0, 0, 0, 100)' }}>A welcome relaxation spot</p>
                      </CarouselCaption>
                    </CarouselItem>
                    <CarouselItem itemId="4">
                      <View>
                      <img
                          className="d-block w-100"
                          src={slide4}
                          alt={slide4}
                        />
                        <Mask overlay="black-slight" />
                      </View>
                      <CarouselCaption>
                        <h3 className="h3-responsive" style={{ textShadow: '2px 2px 2px rgba(0, 0, 0, 100)' }}>Eastern Bedroom</h3>
                        <p style={{ textShadow: '2px 2px 2px rgba(0, 0, 0, 100)' }}>Newly accommodated room oriented on the east</p>
                      </CarouselCaption>
                    </CarouselItem>
                    <CarouselItem itemId="5">
                      <View>
                      <img
                          className="d-block w-100"
                          src={slide5}
                          alt={slide5}
                        />
                        <Mask overlay="black-slight" />
                      </View>
                      <CarouselCaption>
                        <h3 className="h3-responsive" style={{ textShadow: '2px 2px 2px rgba(0, 0, 0, 100)' }}>Western Bedroom</h3>
                        <p style={{ textShadow: '2px 2px 2px rgba(0, 0, 0, 100)' }}>Retro style bedroom oriented on the west</p>
                      </CarouselCaption>
                    </CarouselItem>
                    <CarouselItem itemId="6">
                      <View>
                      <img
                          className="d-block w-100"
                          src={slide6}
                          alt={slide6}
                        />
                        <Mask overlay="black-slight" />
                      </View>
                      <CarouselCaption>
                        <h3 className="h3-responsive" style={{ textShadow: '2px 2px 2px rgba(0, 0, 0, 100)' }}>Garden</h3>
                        <p style={{ textShadow: '2px 2px 2px rgba(0, 0, 0, 100)' }}>Nicely equipped garden with shower and grill</p>
                      </CarouselCaption>
                    </CarouselItem>
                    <CarouselItem itemId="7">
                      <View>
                      <img
                          className="d-block w-100"
                          src={slide7}
                          alt={slide7}
                        />
                        <Mask overlay="black-slight" />
                      </View>
                      <CarouselCaption>
                        <h3 className="h3-responsive" style={{ textShadow: '2px 2px 2px rgba(0, 0, 0, 100)' }}>House Location</h3>
                        <p style={{ textShadow: '2px 2px 2px rgba(0, 0, 0, 100)' }}>Good way to show you the house location from the sky</p>
                      </CarouselCaption>
                    </CarouselItem>
                  </CarouselInner>
                </Carousel>
              </CardBody>
            </Col>
          </Row>
        </FreeBird>
        <Container>
          <Row>
            <Col md="10" className="mx-auto mt-4">
              <h2 className="text-center my-4 font-weight-bold">
                Why choose Holiday Home Barbara?
              </h2>
              <p className="text-center">
                During your holiday, we offer our entire house and its garden
                all JUST for you. We offer full privacy and no disturbing situations.
              </p>
              <p className="text-center">
                The house is only 50m from the beach and its southern exposure offers
                beautiful panoramic views of the open sea and the nearby islands of Brac and Solta.
              </p>
              <p className="text-center">
                Our guests can contact us anytime (24/7) and we will gladly help them.
                We are available for any kind of questions.
              </p>
              <hr className="my-5" />
              <Row id="categories">
                <Col md="4" className="mb-5">
                  <Col size="2" md="2" className="float-left">
                    <FontAwesome
                      className='pink-text'
                      name='car'
                      size='2x'
                      style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}
                      />
                  </Col>
                  <Col size="10" md="8" lg="10" className="float-right">
                    <h4 className="font-weight-bold">PARKING</h4>
                    <p className="grey-text">
                      Free parking in the shade inside house bounds.
                    </p>
                    <NavLink
                      tag="button"
                      className="btn btn-sm indigo darken-3"
                      to="/accommodation"
                    >
                      Read more
                    </NavLink>
                  </Col>
                </Col>
                <Col md="4" className="mb-5">
                  <Col size="2" md="2" className="float-left">
                    <Fa icon="umbrella" className="blue-text" size="2x" />
                  </Col>
                  <Col size="10" md="8" lg="10" className="float-right">
                    <h4 className="font-weight-bold">SEA</h4>
                    <p className="grey-text">
                      The property is only 50 metres from the sea.
                    </p>
                    <NavLink
                      tag="button"
                      className="btn btn-sm indigo lighten-2"
                      to="/accommodation"
                    >
                      Read more
                    </NavLink>
                  </Col>
                </Col>
                <Col md="4" className="mb-5">
                  <Col size="2" md="2" className="float-left">
                    <Fa icon="bank" className="green-text" size="2x" />
                  </Col>
                  <Col size="10" md="8" lg="10" className="float-right">
                    <h4 className="font-weight-bold">KAMERLENGO CASTLE</h4>
                    <p className="grey-text">
                      Castle and fortress in Trogir, only 5km away from Holiday Home Barbara.
                    </p>
                    <NavLink
                      tag="button"
                      className="btn btn-sm indigo darken-3"
                      to="/destination"
                    >
                      Read more
                    </NavLink>
                  </Col>
                </Col>
              </Row>
              <Row id="categories">
                <Col md="4" className="mb-5">
                  <Col size="2" md="2" className="float-left">
                    <Fa icon="building" className="pink-text" size="2x" />
                  </Col>
                  <Col size="10" md="8" lg="10" className="float-right">
                    <h4 className="font-weight-bold">TROGIR CENTER</h4>
                    <p className="grey-text">
                      The house is only 7km away from Trogir town center.
                      This is a must see town.
                    </p>
                    <NavLink
                      tag="button"
                      className="btn btn-sm indigo darken-3"
                      to="/destination"
                    >
                      Read more
                    </NavLink>
                  </Col>
                </Col>
                <Col md="4" className="mb-5">
                  <Col size="2" md="2" className="float-left">
                    <Fa icon="bullseye" className="blue-text" size="2x" />
                  </Col>
                  <Col size="10" md="8" lg="10" className="float-right">
                    <h4 className="font-weight-bold">SPLIT CENTER</h4>
                    <p className="grey-text">
                      About 30 minutes away from the house by car.
                    </p>
                    <NavLink
                      tag="button"
                      className="btn btn-sm indigo lighten-2"
                      to="/destination"
                    >
                      Read more
                    </NavLink>
                  </Col>
                </Col>
                <Col md="4" className="mb-5">
                  <Col size="2" md="2" className="float-left">
                    <Fa icon="plane" className="green-text" size="2x" />
                  </Col>
                  <Col size="10" md="8" lg="10" className="float-right">
                    <h4 className="font-weight-bold">SPLIT AIRPORT</h4>
                    <p className="grey-text">
                      Only 9.5km away from Split Airport Resnik.
                    </p>
                    <NavLink
                      tag="button"
                      className="btn btn-sm indigo darken-3"
                      to="/arrival"
                    >
                      Read more
                    </NavLink>
                  </Col>
                </Col>
              </Row>
              <Row id="categories">
                <Col md="4" className="mb-5">
                  <Col size="2" md="2" className="float-left">
                    <Fa icon="wifi" className="pink-text" size="2x" />
                  </Col>
                  <Col size="10" md="8" lg="10" className="float-right">
                    <h4 className="font-weight-bold">WIFI</h4>
                    <p className="grey-text">
                      Unlimited internet WiFi connection.
                    </p>
                    <NavLink
                      tag="button"
                      className="btn btn-sm indigo darken-3"
                      to="/accommodation"
                    >
                      Read more
                    </NavLink>
                  </Col>
                </Col>
                <Col md="4" className="mb-5">
                  <Col size="2" md="2" className="float-left">
                    <Fa icon="ge" className="blue-text" size="2x" />
                  </Col>
                  <Col size="10" md="8" lg="10" className="float-right">
                    <h4 className="font-weight-bold">AIR CONDITIONER</h4>
                    <p className="grey-text">
                      Protect yourself from heat anytime.
                    </p>
                    <NavLink
                      tag="button"
                      className="btn btn-sm indigo lighten-2"
                      to="/accommodation"
                    >
                      Read more
                    </NavLink>
                  </Col>
                </Col>
                <Col md="4" className="mb-5">
                  <Col size="2" md="2" className="float-left">
                    <Fa icon="paw" className="green-text" size="2x" />
                  </Col>
                  <Col size="10" md="8" lg="10" className="float-right">
                    <h4 className="font-weight-bold">PETS ALLOWED</h4>
                    <p className="grey-text">
                      We offer you possibility to bring your pets with you.
                    </p>
                    <NavLink
                      tag="button"
                      className="btn btn-sm indigo darken-3"
                      to="/accommodation"
                    >
                      Read more
                    </NavLink>
                  </Col>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="my-5">
            <Col md="10" className="mx-auto mt-4">
              <form id="contact-form" name="contact-form" action="mail.php" method="POST">
                <p className="h5 text-center mb-4" style={{ marginTop: "-5rem" }}>Write to us</p>
                <div className="grey-text">
                  <Input
                    label="Your name"
                    icon="user"
                    group
                    type="text"
                    validate
                    error="wrong"
                    success="right"
                  />
                  <Input
                    label="Your email"
                    icon="envelope"
                    group
                    type="email"
                    validate
                    error="wrong"
                    success="right"
                  />
                  <Input
                    label="Subject"
                    icon="tag"
                    group
                    type="text"
                    validate
                    error="wrong"
                    success="right"
                  />
                  <Input
                    type="textarea"
                    rows="2"
                    label="Your message"
                    icon="pencil"
                  />
                </div>
                <div className="text-center">
                  <Button outline color="primary">
                    Send <Fa icon="paper-plane-o" className="ml-1" />
                  </Button>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
        <Container style={{ marginBottom: "-1.5rem" }}>
          <Footer color="blue" className="font-small pt-4 mt-4">
            <Container fluid className="text-center text-md-left">
              <Row>
                <Col md="6">
                  <h5 className="title">Holiday Home Barbara</h5>
                  <h6 className="title">Okrug Gornji, Čiovo – Trogir</h6>
                  <p>Put Rastića 13, HR-21223 Okrug Gornji</p>
                  <p><Fa icon="phone" className="white-text" size="1x" /> +385 95 514 4100</p>
                  <p style={{ marginTop: "-1rem" }}><Fa icon="envelope" className="white-text" size="1x" /> darija.santic5@gmail.com</p>
                </Col>
                <Col md="6">
                  <h5 className="title">Reservation</h5>
                  <p>You can contact us directly via contact form and we will find ideal solution for your vacation.</p>
                  <p>We need to ask you 30% upfront if you want to book here.</p>
                </Col>
              </Row>
            </Container>
          </Footer>
        </Container>
      </div>
    );
  }
}

export default HomePage;