import React, { Component } from "react";
import { Card, CardBody, Container } from "mdbreact";

class ContactPage extends Component {
  render() {
    return (
      <Container style={{ marginTop: "5rem" }}>
        <section className="m-5">
          <h2 className="mt-5" style={{ marginBottom: "1rem" }}>Under construction</h2>
          <Card>
            <CardBody>
              <p style={{ marginTop: "-0.2rem" }}>This section is currently under construction.</p>
              <p style={{ marginTop: "-0.5rem", marginBottom: "-0.2rem" }}>Please visit us later.</p>
            </CardBody>
          </Card>
        </section>
      </Container>
    );
  }
}

export default ContactPage;