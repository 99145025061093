import React from "react";
import { Route, Switch } from "react-router-dom";

import HomePage from "./pages/HomePage";
import AccommodationPage from "./pages/AccommodationPage";
import DestinationPage from "./pages/DestinationPage";
import ArrivalPage from "./pages/ArrivalPage";
import PhotosPage from "./pages/PhotosPage";
import ContactPage from "./pages/ContactPage";

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={HomePage} />
        
        <Route path="/accommodation" component={AccommodationPage} />
        <Route path="/destination" component={DestinationPage} />
        <Route path="/arrival" component={ArrivalPage} />
        <Route path="/photos" component={PhotosPage} />
        <Route path="/contact" component={ContactPage} />
        <Route
          render={function() {
            return <h1>Not Found</h1>;
          }}
        />
      </Switch>
    );
  }
}

export default Routes;